<template>
  <div
    class="subheader py-2 py-lg-4"
    :class="subheaderClasses"
    id="dp_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!-- <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5> -->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              Ana Sayfa
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <span style="color: #949494">/</span>
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted ml-2"
              >
                {{ breadcrumb.title }}
              </router-link>
              <a
                href="javascript:;"
                v-else-if="breadcrumb.emit"
                :key="i"
                @click="$EventBus.$emit(breadcrumb.emit)"
                class="text-muted ml-2"
              >
                {{ breadcrumb.title }}
              </a>
              <a
                href="javascript:;"
                v-else-if="breadcrumb.goBack"
                :key="i"
                @click="$router.go(-1)"
                class="text-muted ml-2"
              >
                {{ breadcrumb.title }}
              </a>
              <a href="javascript:;" class="text-muted ml-2" :key="i" v-else>
                {{ breadcrumb.title }}
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DPSubheader',
  props: {
    breadcrumbs: Array,
    title: String,
  },
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig('subheader.width') === 'fluid';
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig('subheader.style');
      if (style) {
        classes.push(style);

        if (style === 'solid') {
          classes.push('bg-white');
        }

        if (this.layoutConfig('subheader.fixed')) {
          classes.push('');
        }
      }
      return classes.join(' ');
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
